import useDictStore from '@/store/modules/dict'
import {getDicts} from '@/api/system/dict/data'
import {type Ref, ref, toRefs} from "vue";

export interface SysDict {
    dictLabel: string;
    dictValue: string;
    listClass: string;
    cssClass: string;
}

export interface Dict {
    label: string,
    value: string,
    elTagType: string,
    elTagClass: string
}

/**
 * 获取字典数据
 */
export function useDict(...args: string[]) {
    const res: Ref = ref({});
    return (() => {
        args.forEach((dictType) => {
            res.value[dictType] = [];
            const dicts = useDictStore().getDict(dictType);
            if (dicts) {
                res.value[dictType] = dicts;
            } else {
                getDicts(dictType).then(resp => {
                    res.value[dictType] = resp.data.map((p: SysDict) => ({
                        label: p.dictLabel,
                        value: p.dictValue,
                        elTagType: p.listClass,
                        elTagClass: p.cssClass
                    }))
                    useDictStore().setDict(dictType, res.value[dictType]);
                })
            }
        })
        return toRefs(res.value);
    })()
}

export function getLabel(dict: Object, values: string | string[]): string {
    let label = '';
    if (typeof values === 'string') {
        // @ts-ignore
        label =  dict[values]?.label || ''
    } else if (typeof values === 'object') {
        label = values.map(item => {
            // @ts-ignore
            console.log(dict[item])
            // @ts-ignore
            return dict[item]?.label || ''
            // @ts-ignore
        }).filter(item => !!item).toString()
    }
    return label
}