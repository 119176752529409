import axios from 'axios'
import {ElMessage} from 'element-plus'
import {saveAs} from 'file-saver'
import {getToken} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import {blobValidate} from '@/utils/ruoyi'

const baseURL = import.meta.env.VITE_APP_BASE_API

export default {
    name(name, isDelete = true) {
        var url = baseURL + "/common/download?fileName=" + encodeURIComponent(name) + "&delete=" + isDelete
        axios({
            method: 'get',
            url: url,
            responseType: 'blob',
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).then((res) => {
            const isBlob = blobValidate(res.data);
            if (isBlob) {
                const blob = new Blob([res.data])
                this.saveAs(blob, decodeURIComponent(res.headers['download-filename']))
            } else {
                this.printErrMsg(res.data);
            }
        })
    },
    resource(resource) {
        var url = baseURL + "/common/download/resource?resource=" + encodeURIComponent(resource);
        axios({
            method: 'get',
            url: url,
            responseType: 'blob',
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).then((res) => {
            const isBlob = blobValidate(res.data);
            if (isBlob) {
                const blob = new Blob([res.data])
                this.saveAs(blob, decodeURIComponent(res.headers['download-filename']))
            } else {
                this.printErrMsg(res.data);
            }
        })
    },
    zip(url, name) {
        var url = baseURL + url
        axios({
            method: 'get',
            url: url,
            responseType: 'blob',
            headers: {'Authorization': 'Bearer ' + getToken()}
        }).then((res) => {
            const isBlob = blobValidate(res.data);
            if (isBlob) {
                const blob = new Blob([res.data], {type: 'application/zip'})
                this.saveAs(blob, name)
            } else {
                this.printErrMsg(res.data);
            }
        })
    },
    saveAs(text, name, opts) {
        saveAs(text, name, opts);
    },
    async printErrMsg(data) {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
        ElMessage.error(errMsg);
    },
    getFileName(path) {
        var index = path.lastIndexOf("/"); // lastIndexOf("/")  找到最后一个  /  的位置
        var fileName = path.substr(index + 1); // substr() 截取剩余的字符，即得文件名xxx.doc
        return fileName;
    }
}

