<template>
  <div style="padding: 0 15px;" @click="toggleClick">
    <template v-if="isActive">
      <el-icon color="white" class="hamburger" size="20">
        <Fold></Fold>
      </el-icon>
    </template>
    <template v-else>
      <el-icon color="white" class="hamburger" size="20">
        <Expand></Expand>
      </el-icon>
    </template>
  </div>
</template>

<script setup>
defineProps({
  isActive: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits()
const toggleClick = () => {
  emit('toggleClick');
}
</script>

<style scoped lang="scss">
.hamburger {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 100px;
  color: #fff;

}

</style>
