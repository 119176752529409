<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Notification',
})
</script>
<template>
  <div>
    <el-dropdown trigger="click">
      <template #default>
        <div style="height: 100px;line-height: 100px;">
          <svg-icon style="
              font-size: 18px;color: #fff" class-name="search-icon"
                    :icon-class=" hasNotification ? 'hasNotification' : 'notNotification'"/>
        </div>
      </template>
      <template #dropdown>
        <el-dropdown-menu>
          <router-link to="/inspection/task">
            <el-dropdown-item>
            <span v-if="taskCount > 0">
               待巡检任务:<span style="color: #f46c6c">{{ taskCount }} </span><span
                style="font-size: var(--el-font-size-extra-small)">(个)</span>
            </span>
              <span v-else>
              暂无巡检任务
            </span>
            </el-dropdown-item>
          </router-link>
          <router-link to="/user/message">
            <el-dropdown-item divided>
            <span v-if="msgCount > 0">
              未读消息: <span style="color: #e16565">{{ msgCount }}</span> <span
                style="font-size: var(--el-font-size-extra-small)">(条)</span>
            </span>
              <span v-else>
              暂无未读消息
            </span>
            </el-dropdown-item>
          </router-link>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup lang="ts" name="Notification">
import useUserStore from "@/store/modules/user";
import {computed} from "vue";

function click() {

}

const userStore = useUserStore();
const taskCount = computed<number>(() => userStore.taskCount);
const msgCount = computed<number>(() => userStore.unreadMsgAmount);
const hasNotification = computed(() => taskCount.value + msgCount.value > 0);
</script>

<style>
.point {
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 6px;
  background: red;
  border-radius: 50%;
}
</style>