import router from "@/router";
import {useDark,useToggle} from "@vueuse/core";

// 深色模式页面
const darkList = ['/visualization'];

// 前置路由守卫
router.afterEach((to, from) => {
    let isDark = useDark()
    let toggle = useToggle(isDark);
    if (darkList.indexOf(to.path) !== -1) {
        if (!isDark.value) {
            toggle()
        }
    }else {
        if (isDark.value) {
            toggle()
        }
    }
})