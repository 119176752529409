// @ts-ignore
import Cookies from 'js-cookie'
import {defineStore} from "pinia/dist/pinia";
import type {WarningInfo} from "@/type/warn/WarningInfoListVo";
import useUserStore from "@/store/modules/user";
import {uniqBy} from 'lodash-es'
const useWebSocket = defineStore(
    'webSocket',
    {
        state: () => ({
            connection: undefined,
            warnMessageList: []
        } as { connection?: WebSocket; warnMessageList: WarningInfo[]; }),
        actions: {
            connect() {
                if (this.connection || !useUserStore().token) {
                    console.log("拒绝链接ws")
                    return
                }
                const { host } = location
                const client = new WebSocket(`wss://${host}/warning/real`);
                client.onmessage = ({data}) => {
                  const warnList = <WarningInfo[]>JSON.parse(data);
                    const list = [...this.warnMessageList,...warnList]
                    this.warnMessageList = uniqBy(list, 'id');
                }
                client.onopen = event => {
                    console.log("ws 连接成功")

                }
                this.connection = client;
            },
            disconnect() {
                this.connection?.close();
            },
            clearWarningList() {
                this.warnMessageList = [];
            }
        }
    }
)

export default useWebSocket;