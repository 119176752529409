import request from '@/utils/request'

// 查询消息列表
export function listMessage(query) {
  return request({
    url: '/system/message/list',
    method: 'get',
    params: query
  })
}

// 查询消息详细
export function getMessage(msgId) {
  return request({
    url: '/system/message/' + msgId,
    method: 'get'
  })
}

// 新增消息
export function addMessage(data) {
  return request({
    url: '/system/message',
    method: 'post',
    data: data
  })
}

// 修改消息
export function updateMessage(data) {
  return request({
    url: '/system/message',
    method: 'put',
    data: data
  })
}

// 批量修改消息
export function batchUpdateMessage(data) {
  return request({
    url: '/system/message/batch',
    method: 'put',
    data: data
  })
}

// 删除消息
export function delMessage(msgId) {
  return request({
    url: '/system/message/' + msgId,
    method: 'delete'
  })
}

export function getUnreadAmount() {
  return request({
    url: '/system/message/unread/amount',
    method: 'get'
  })
}
