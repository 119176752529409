import request from '@/utils/request'

const baseUrl = '/warn/level/'

export function getWarningLevel() {
    return request({
        url: baseUrl + 'list',
        method: 'get'
    })
}

export function getWarningLevelMap() {
    return request({
        url: baseUrl + 'map',
        method: 'get'
    })
}