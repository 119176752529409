<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RealWanDialog',
})
</script>
<template>
  <el-card v-show="visible" class="zs-notification">
    <div class="zs-notification-close-btn">
      <el-icon style="height: 2.5rem;" @click="cancel"><Close/></el-icon>
    </div>
    <h3 class="zs-notification-title">预警信息 <small> <!--TODO 显示预警数量--></small></h3>
    <el-scrollbar height="calc(65px * 3)">
      <div v-for="(warnInfo,index) in warnMessageList" class="zs-notification-item">

        <!--  预警项   -->
        <el-alert data-title="点击展开/折叠" type="warning" style="width: 100%" @click="handleTrigger(warnInfo,index)">
          <template #title>
              <span>
                <el-image style="height: 18px;vertical-align: bottom"
                          :src="WarnMap[warnLevel(warnInfo.levelId).colorName]"/>
                {{ warnLevel(warnInfo.levelId).levelName }}
              </span> - {{ warnInfo.warningTime }}
          </template>
          <div v-show="expendIndex === index">
            {{ warnInfo.warningDescription }}
            <el-row>
<!--              TODO 其他操作-->
            </el-row>
          </div>
          <div v-show="expendIndex !== index" style="width: 100%" class="text-hidden">
            {{ warnInfo.warningDescription }}
          </div>

        </el-alert>
      </div>
    </el-scrollbar>
  </el-card>
</template>

<script setup name="RealWanDialog" lang="ts">
import {computed, reactive, ref, watch} from "vue";
import {WarnMap} from "@/views/warn";
import useWebSocket from "@/store/modules/webSocket";
import type {WarningInfo} from "@/type/warn/WarningInfoListVo";
import {getWarningLevel} from "@/api/warn/level";
import type {WarnLevel} from "@/type/warn/WarnLevel";

const webSocketStore = useWebSocket()
const warnLevelOptions = ref<WarnLevel[]>();

function cancel() {
  webSocketStore.clearWarningList();
}

const warnMessageList = computed<WarningInfo[]>(() => webSocketStore.warnMessageList);

const dialog = reactive(
    {
      visible: false
    }
)

const warnLevel = computed(() => (levelId: number) => {
  const find = warnLevelOptions.value?.find(item => item.id === levelId);
  return find || {};
})
const visible = computed(() => warnMessageList.value?.length > 0)

watch(warnMessageList, (newVal) => {
  console.log(newVal);
})

const expendIndex = ref<number>(-1);

function handleTrigger(warnInfo: WarningInfo, index: number) {
  expendIndex.value = expendIndex.value === index ? -1 : index;
}

getWarningLevel().then(res => {
  warnLevelOptions.value = res.data
})

</script>

<style scoped lang="scss">
.zs-notification {
  position: fixed;
  right: 0.3rem;
  bottom: 0.3rem;
  width: 25rem;
  z-index: 2000;
}

.zs-notification-title {
  margin-block-start: 0.5rem;
  margin-block-end: 1rem;
  line-height: 1rem;
}

.zs-notification-close-btn {
  position: absolute;
  top: 15px;
  right: 18px;

  &:hover {
    color: #afb4b4;
    cursor: pointer;
  }
}

.zs-notification-item {
  &:hover {
    cursor: pointer;
    //text-decoration-line: underline;
    //text-decoration-color: #1c84c6;
  }

  :deep .el-alert__content {
    width: 100%;

    .el-alert__title .is-bold {
      font-weight: 500;
    }

    .el-alert__description {
      font-size: 14px;
    }

  }

  margin-bottom: 5px;
}


</style>