<template>
  <div>
    <span class="iconfont icon-dapingzhanshi-copy"  style="font-size: 18px;color: #fff"  @click="toggle"></span>
  </div>
</template>

<script setup>
const router = useRouter();

function toggle(){
  router.push({ path: "/visualization" });
}

</script>

<style scoped>

</style>