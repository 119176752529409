// @ts-ignore
import blueImage from '@/assets/iconfont/a-jingbaoyujingjijiu-blue.png'
// @ts-ignore
import yellowImage from '@/assets/iconfont/a-jingbaoyujingjijiu-yellow.png'
// @ts-ignore
import orangeImage from '@/assets/iconfont/a-jingbaoyujingjijiu-orange.png'
// @ts-ignore
import redImage from '@/assets/iconfont/a-jingbaoyujingjijiu-red.png'

export const WarnMap = {
    "blue": blueImage,
    "yellow": yellowImage,
    "orange": orangeImage,
    "red": redImage,
}

