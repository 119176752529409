<template>
  <router-view/>
  <real-warn-notification/>
</template>

<script setup>
import useSettingsStore from '@/store/modules/settings'
import {handleThemeStyle} from '@/utils/theme'

import {computed, onMounted, reactive, watch} from "vue";
import useAppStore from "@/store/modules/app";
import useWebSocket from "@/store/modules/webSocket";
import RealWarnNotification from "@/RealWarnNotification";
const webSocketStore = useWebSocket()



onMounted(() => {
  document.body.style.setProperty("--el-color-primary", "#3986D6");
  document.body.style.setProperty("--el-color-danger", "#F14D4D");
  document.body.style.setProperty("--el-color-warning", "#C8892C");
  document.body.style.setProperty("--el-color-success", "#62CC2E");
  // document.body.style.setProperty("--border-color-base", "#B0C1EB");
  // document.body.style.setProperty("--background-color-base", "#FCFCFC");

  nextTick(() => {
    // 初始化主题样式
    handleThemeStyle(useSettingsStore().theme)

    const appStore = useAppStore();
    appStore.setSize('large')
    webSocketStore.connect()


  });

})


</script>

<style scoped>

</style>
